import { constantRoutes } from '@/router'
import store from '@/store';

let authList = [];

let usertype = null // 用户类别，0-总平台 1-企业平台
let userstyle = null // 用户类型：总平台：0-总账号 1-客服 2-销售员 3-维修员 企业平台：0-管理员 1-厨师 2-服务员

let groups = null // 权限组

function getisAuth(GroupId) { // 每一个路由id
    // true显示 false隐藏

    // 路由没有GroupId  正常显示
    if (!GroupId) {
        return true;
    }
    for (let i = 0; i < authList.length; i++) {
        if (authList[i].GroupId == GroupId) {
            return true;
        }

        // 如果权限有 路由显示
        // if (authList[i].GroupId == 9) {
        //     if (usertype == 0) {
        //         return true;
        //     } else {
        //         return false;
        //     }
        // } else if (authList[i].GroupId == GroupId) {
        //     return true;
        // }
    }
    // console.log('否则不显示GroupId', GroupId);
    // 否则不显示
    return false;
}



export function getMenus() {
    // console.log('useruseruseruseruser', store.state.user);

    // 用户类别，0-总平台 1-企业平台
    usertype = store.state.user.userinfo.usertype

    // 用户类型：总平台：0-渠道 1-经理 2-销售员 3-维修员 4-客服
    //          企业平台：0-管理员 1-厨师 2-服务员
    userstyle = store.state.user.userinfo.userstyle

    authList = store.state.user.menus

    groups = store.state.user.userinfo.groups
    // console.log('groups', groups);

    let tempArr = [] // 权限组的字符串

    groups.forEach(ele => {
        tempArr.push(ele.GroupName)
    })

    console.log('tempArr.join()', tempArr.join().includes('系统管理员'));

    // *GroupId：2菜谱 3AI 4押金贷 5客服 6销售 7维修 9企业 10订单 11设备 12消息 19系统设置 20账号 
    
    // !!!!!!!!!!!!!!!!!!!!!总平台
    if (usertype == 0) {
        if (userstyle == 0) {
            authList.push({ GroupId: 19 }, { GroupId: 20 })
        }
        constantRoutes.forEach(item => {
            const GroupId = item.GroupId; // 每一个路由的id

            item.hidden = !getisAuth(GroupId)
            if (item.children && item.children.length > 0) {
                item.children.forEach(ele => {
                    ele.meta.hidden
                })
            }
        })
        let arr = []
        constantRoutes.forEach(ele => {
            if (ele.hidden != true) {
                arr.push(ele)
            }
        })
        // console.log(arr);
        return arr;
        // return constantRoutes;
    }
    // !!!!!!!!!!!!!!!!!!!!!企业平台
    else if (usertype == 1) {
        if (userstyle == 0) {
            authList.push({ GroupId: 19 }, { GroupId: 20 })
        }
        constantRoutes.forEach(item => {
            const GroupId = item.GroupId; // 每一个路由的id

            item.hidden = !getisAuth(GroupId)
            if (item.children && item.children.length > 0) {
                item.children.forEach(ele => {
                    ele.meta.hidden = !getisAuth(GroupId)
                })
            }
        })
        // console.log('constantRoutes', constantRoutes);
        let arr = []
        constantRoutes.forEach(ele => {
            if (ele.hidden != true) {
                arr.push(ele)
            }
        })
        // console.log(arr);
        return arr;
    } else {
        constantRoutes.forEach(item => {
            const GroupId = item.GroupId; // 每一个路由的id

            item.hidden = !getisAuth(GroupId)
            if (item.children && item.children.length > 0) {
                item.children.forEach(ele => {
                    ele.meta.hidden = !getisAuth(GroupId)
                })
            }
        })
        // console.log('constantRoutes', constantRoutes);
        let arr = []
        constantRoutes.forEach(ele => {
            if (ele.hidden != true) {
                arr.push(ele)
            }
        })
        // console.log(arr);
        return arr;
    }
}