<template>
  <div v-if="!item.hidden">
    <!-- 没子级 -->
    <template
      v-if="hasOneShowingChild(item.children, item) && (!onlyOneChild.children || onlyOneChild.noShowingChildren) && !item.alwaysShow">
      <app-link v-if="onlyOneChild.meta" :to="resolvePath(onlyOneChild.path)">
        <el-menu-item class="activeSilderBox" :index="resolvePath(onlyOneChild.path)"
          :class="{ 'submenu-title-noDropdown': !isNest }">
          <template v-if="newPath == onlyOneChild.meta.myPath">
            <item :icon="onlyOneChild.meta.icon2 || (item.meta && item.meta.icon2)" :title="onlyOneChild.meta.title" />
          </template>
          <template v-else-if="onlyOneChild.path != ''">
            <item :icon="onlyOneChild.meta.icon || (item.meta && item.meta.icon)" :title="onlyOneChild.meta.title" />
          </template>
          <template v-else>
            <item :icon="onlyOneChild.meta.icon || (item.meta && item.meta.icon)" :title="onlyOneChild.meta.title" />
          </template>
          <div class="activeLine" v-if="newPath == onlyOneChild.meta.myPath"></div>
          <!-- </div> -->
        </el-menu-item>
      </app-link>
    </template>

    <!-- 有child -->
    <el-submenu v-else ref="subMenu" :index="resolvePath(item.path)" popper-append-to-body>
      <template slot="title">
        <!-- 高亮在子级 -->
        <template v-if="(item.path).split('/')[1] == newPath.split('/')[1]">
          <item v-if="item.meta" :icon="item.meta && item.meta.icon2" :title="item.meta.title" :path="item.path" />
        </template>
        <!-- 高亮不在子级 -->
        <template v-else>
          <item v-if="item.meta" :icon="item.meta && item.meta.icon" :title="item.meta.title" :path="item.path" />
        </template>
      </template>
      <sidebar-item :activeMenu="activeMenu" v-for="child in item.children" :key="child.path" :is-nest="true"
        :item="child" :base-path="resolvePath(child.path)" class="nest-menu" />
    </el-submenu>
  </div>
</template>

<script>
// import path from 'path'
import path from 'path-browserify'
import { isExternal } from '@/utils/validate'
import Item from './Item'
import AppLink from './Link'
import FixiOSBug from './FixiOSBug'

export default {
  name: 'SidebarItem',
  components: { Item, AppLink },
  mixins: [FixiOSBug],
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ''
    },
    activeMenu: {
      type: String,
      default: ''
    }
  },
  data() {
    // To fix https://github.com/PanJiaChen/vue-admin-template/issues/237
    // TODO: refactor with render function
    this.onlyOneChild = null
    return {
      newPath: ''
    }
  },
  watch: {
    activeMenu: {
      handler(newPath) {
        if (newPath != '') {
          // console.log('=========newPath========', newPath)
          this.newPath = newPath
        }
      },
      immediate: true
    }
  },
  methods: {
    hasOneShowingChild(children = [], parent) {
      const showingChildren = children.filter(item => {
        if (item.hidden) {
          return false
        } else {
          // Temp set(will be used if only has one showing child)
          this.onlyOneChild = item
          return true
        }
      })

      // When there is only one child router, the child router is displayed by default
      if (showingChildren.length === 1) {
        return true
      }

      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        this.onlyOneChild = { ...parent, path: '', noShowingChildren: true }
        return true
      }

      return false
    },
    resolvePath(routePath) {
      if (isExternal(routePath)) {
        return routePath
      }
      if (isExternal(this.basePath)) {
        return this.basePath
      }
      return path.resolve(this.basePath, routePath)
    }
  }
}
</script>

<style lang="scss" scoped>
.activeSilderBox {
  position: relative;

  .activeLine {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 4.36px;
    height: 100%;
    background: #FF8045;
  }
}
</style>
