// import { login, logout, getInfo } from '@/api/user'
import { resetRouter } from '@/router'

const getDefaultState = () => {
  return {
    name: '',
    avatar: '',
    roles: [], // 角色权限控制按钮显示
    menus: [], // 菜单权限
    userinfo: {}
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_USERINFO: (state, userinfo) => {
    state.userinfo = userinfo
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles // 角色权限
  },
  SET_MENUS: (state, menus) => {
    state.menus = menus // 菜单权限
  }
}

const actions = {
  // user login
  login({ commit }, userInfo) {
    const { username, password } = userInfo
    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password: password }).then(response => {
        const { data } = response
        console.log('loginResponse', response);
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo(state.token).then(response => {
        const { data } = response

        if (!data) {
          return reject('验证失败，请重新登录')
        }

        const { name, avatar } = data

        data.menuList.push({ 'path': '/404', 'component': '404', 'hide': 'true' }, {
          'path': '*',
          'redirect': '/404',
          'hidden': 'true'
        })

        commit('SET_NAME', data.admin.username)
        commit('SET_AVATAR', data.admin.headimg)
        commit('SET_ROLES', data.roleNameList) // 角色权限
        commit('SET_MENUS', data.menuList) // 菜单权限
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      logout(state.token).then(() => {
        removeToken() // must remove  token  first
        resetRouter()
        commit('RESET_STATE')
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      removeToken() // must remove  token  first
      commit('RESET_STATE')
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

// {
//   "code": 20000,
//
//     "msg": "业务成功",
//
//     "data": {
//   "menuList": [
//
//     {
//       "menuId": 7,
//
//       "path": "/admin",
//
//       "name": "admin",
//
//       "title": "用户管理",
//
//       "icon": "el-icon-s-help",
//
//       "component": "Layout",
//
//       "parentId": 0,
//
//       "redirect": "/admin/list",
//
//       "children": [
//
//         {
//           "menuId": 8,
//
//           "path": "/admin/list",
//
//           "name": "list",
//
//           "title": "用户列表信息",
//
//           "icon": "el-icon-s-help",
//
//           "component": "admin/list",
//
//           "parentId": 7,
//
//           "redirect": "",
//
//           "children": [],
//
//           "meta": {
//             "title": "用户列表信息",
//
//             "icon": "el-icon-s-help"
//
//           }
//
//         },
//
//         {
//           "menuId": 9,
//
//           "path": "/admin/tree",
//
//           "name": "Tree",
//
//           "title": "用户操作日志",
//
//           "icon": "el-icon-s-help",
//
//           "component": "Layout",
//
//           "parentId": 7,
//
//           "redirect": null,
//
//           "children": [],
//
//           "meta": {
//             "title": "用户操作日志",
//
//             "icon": "el-icon-s-help"
//
//           }
//
//         }
//
//       ],
//
//       "meta": {
//         "title": "用户管理",
//
//         "icon": "el-icon-s-help"
//
//       }
//
//     }
//
//   ],
//
//       "roleNameList": [
//
//     "主管",
//
//     "普通用户"
//
//   ],
//
//       "admin": {
//     "id": 36,
//
//         "username": "小五爱喝珍珠奶茶",
//
//         "account": "admin123456",
//
//         "status": "2",
//
//         "number": "A-517067",
//
//         "workdate": "2022-07-13",
//
//         "email": "1323349692@qq.com",
//
//         "phone": null,
//
//         "headimg": "https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif",
//
//         "isDeleted": "0",
//
//         "createDate": "2022-07-13 04:36:49",
//
//         "updateDate": "2022-07-13 04:36:49",
//
//         "requestid": null,
//
//         "respcode": null,
//
//         "respmessage": null,
//
//         "respbizid": null,
//
//         "businesstype": "admin-register",
//
//         "registertype": "1",
//
//         "remark3": null,
//
//         "remark2": null,
//
//         "remark1": "CWgLOJ/cWwxc9g3tbuxsmA==",
//
//         "code": null
//
//   }
//
// }
//
// }

