import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from "@/layout";

Vue.use(VueRouter)

export const constantRoutes = [
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/login/index')
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../views/login/register')
    },
    {
        path: '/',
        redirect: '/stewardHome',
        component: Layout,
        children: [
            {
                path: 'stewardHome',
                name: 'stewardHome',
                meta: {
                    title: '首页', icon: '/assets/index/1.png',
                    icon2: '/assets/index/1a.png', myPath: '/stewardHome'
                },
                component: () => import('../views/steward/index/index')
            }]
    },
    {
        path: '/deviceGuard',
        redirect: '/deviceGuard/index',
        component: Layout,
        GroupId: 11,
        hidden: false,
        children: [
            {
                path: 'index',
                name: 'index',
                meta: {
                    title: '设备管理', icon: '/assets/index/2.png',
                    icon2: '/assets/index/2a.png', myPath: '/deviceGuard/index',
                    hidden: false,
                },
                component: () => import('../views/steward/deviceGuard/index')
            }]
    },
    {
        path: '/orderGuard',
        redirect: '/orderGuard/index',
        component: Layout,
        GroupId: 10,
        hidden: false,
        children: [
            {
                path: 'index',
                name: 'index',
                meta: {
                    title: '订单管理', icon: '/assets/index/3.png',
                    icon2: '/assets/index/3a.png', myPath: '/orderGuard/index',
                    hidden: false,
                },
                component: () => import('../views/steward/orderGuard/index')
            }]
    },
    {
        path: '/saveGuard',
        component: Layout,
        redirect: '/saveGuard/index',
        hidden: false,
        GroupId: 7,
        children: [
            {
                path: 'index',
                name: 'index',
                meta: {
                    title: '维保管理', icon: '/assets/index/4.png',
                    icon2: '/assets/index/4a.png', myPath: '/saveGuard/index',
                    hidden: false,
                },
                component: () => import('../views/steward/saveGuard/index')
            }]
    },
    {
        path: '/moneyPage',
        component: Layout,
        redirect: '/moneyPage/index',
        hidden: false,
        GroupId: 4,
        children: [
            {
                path: 'index',
                name: 'index',
                meta: {
                    title: '押金贷', icon: '/assets/index/5.png',
                    icon2: '/assets/index/5a.png', myPath: '/moneyPage/index',
                    hidden: false,
                },
                component: () => import('../views/steward/moneyPage/index')
            }]
    },
    {
        path: '/dataTotal',
        component: Layout,
        redirect: '/dataTotal/index',
        children: [
            {
                path: 'index',
                name: 'index',
                meta: {
                    title: '数据统计', icon: '/assets/index/6.png',
                    icon2: '/assets/index/6a.png', myPath: '/dataTotal/index'
                },
                component: () => import('../views/steward/dataTotal/index')
            }]
    },
    {
        path: '/msgNotice',
        component: Layout,
        redirect: '/msgNotice/index',
        GroupId: 12,
        hidden: false,
        children: [
            {
                path: 'index',
                name: 'index',
                meta: {
                    title: '消息通知', icon: '/assets/index/7.png',
                    icon2: '/assets/index/7a.png', myPath: '/msgNotice/index',
                    hidden: false,
                },
                component: () => import('../views/steward/msgNotice/index')
            }
        ]
    },
    {
        path: '/saleGuard',
        component: Layout,
        redirect: '/saleGuard/index',
        hidden: false,
        GroupId: 6,
        children: [
            {
                path: 'index',
                name: 'index',
                meta: {
                    title: '销售管理', icon: '/assets/index/8.png',
                    icon2: '/assets/index/8a.png', myPath: '/saleGuard/index',
                    hidden: false,
                },
                component: () => import('../views/steward/saleGuard/index')
            },
        ]
    },
    {
        path: '/systemSetting',
        component: Layout,
        redirect: '/systemSetting/index',
        hidden: false,
        GroupId: 19,
        children: [
            {
                path: 'index',
                name: 'index',
                meta: {
                    title: '系统设置', icon: '/assets/index/9.png',
                    icon2: '/assets/index/9a.png', myPath: '/systemSetting/index',
                    hidden: false,
                },
                component: () => import('../views/steward/systemSetting/index')
            },
        ]
    },
    {
        path: '/accountGuard',
        component: Layout,
        redirect: '/accountGuard/index',
        hidden: false,
        GroupId: 20,
        children: [
            {
                path: 'index',
                name: 'index',
                meta: {
                    title: '账号管理', icon: '/assets/index/8.png',
                    icon2: '/assets/index/8a.png', myPath: '/accountGuard/index',
                    hidden: false,
                },
                component: () => import('../views/steward/accountGuard/index')
            }]
    },
    {
        path: '/cook',
        component: Layout,
        redirect: '/cook/index',
        hidden: false,
        GroupId: 3,
        meta: {
            title: 'AI炒菜', icon: '/assets/index/10.png',
            icon2: '/assets/index/10a.png', myPath: '/cook/index'
        },
        hidden: false,
        children: [
            {
                path: 'index',
                name: 'index',
                meta: {
                    title: 'AI炒菜',
                    myPath: '/cook/index',
                    hidden: false,
                },
                component: () => import('../views/steward/cook/index')
            },
            {
                path: 'history',
                name: 'history',
                meta: {
                    title: 'AI炒菜记录',
                    myPath: '/cook/history',
                    hidden: false,
                },
                component: () => import('../views/steward/cook/history')
            }]
    },
    {
        path: '/menuGuard',
        component: Layout,
        redirect: '/menuGuard/index',
        hidden: false,
        GroupId: 2,
        meta: {
            title: '菜谱管理', icon: '/assets/index/11.png',
            icon2: '/assets/index/11a.png', myPath: '/menuGuard/index'
        },
        children: [
            {
                path: 'index',
                name: 'index',
                meta: {
                    title: '全部菜谱',
                    myPath: '/menuGuard/index',
                    hidden: false,
                },
                component: () => import('../views/steward/menuGuard/index')
            },
            {
                path: 'shangjia',
                name: 'shangjia',
                meta: {
                    title: '上架管理',
                    myPath: '/menuGuard/shangjia',
                    hidden: false,
                },
                component: () => import('../views/steward/menuGuard/shangjia')
            },
        ]
    },
    {
        path: '/getMoney',
        component: Layout,
        redirect: '/getMoney/index',
        children: [
            {
                path: 'index',
                name: 'index',
                meta: {
                    title: '收费模板', icon: '/assets/index/13.png',
                    icon2: '/assets/index/13a.png', myPath: '/getMoney/index'
                },
                component: () => import('../views/steward/getMoney/index')
            },
        ]
    },
    {
        path: '/company',
        component: Layout,
        redirect: '/company/index',
        hidden: false,
        GroupId: 9,
        children: [
            {
                path: 'index',
                name: 'index',
                meta: {
                    title: '商户管理', icon: '/assets/index/14.png',
                    icon2: '/assets/index/14a.png', myPath: '/company/index',
                    hidden: false,
                },
                component: () => import('../views/steward/company/index')
            },
        ]
    },
    {
        path: '/channelGuard',
        component: Layout,
        redirect: '/channelGuard/index',
        children: [
            {
                path: 'index',
                name: 'index',
                meta: {
                    title: '渠道管理', icon: '/assets/index/12.png',
                    icon2: '/assets/index/12a.png', myPath: '/channelGuard/index'
                },
                component: () => import('../views/steward/channelGuard/index')
            },
        ]
    },
    {
        path: '/404',
        name: '/404',
        component: () => import('../views/error/404')
    },
    {
        path: '*',
        component: () => import('../views/error/404')
    }

]
const createRouter = () => new VueRouter({
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
})

const router = createRouter()

export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}


export default router
