import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import '@/icons' // icon
import '@/permission' // permission control
import '@/styles/index.scss' // global css

import '../src/icons/iconfont.css'

import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
Vue.use(VXETable)

import Antd from 'ant-design-vue';
Vue.use(Antd)

//引入svg组件
import IconSvg from '@/components/SvgIcon'
//全局注册icon-svg
Vue.component('icon-svg', IconSvg)

Vue.use(ElementUI);
Vue.config.productionTip = false

let userinfo = localStorage.getItem("AiUserInfo");
if (userinfo) {
  try {
    userinfo = JSON.parse(userinfo);
  } catch (e) {
    userinfo = null;
  }
  if (userinfo) {
    store.state.user.userinfo = userinfo;
    store.state.user.menus = userinfo.groups;
  }
}

router.beforeEach((to, from, next) => {
  // console.log('router.beforeEach-----store.state.user', store.state.user);
  // console.log('toooooooooooooooo', to);
  if (to.meta.hidden == true) {
    // console.log(40444444444444444);
    next('/404');
  } else if ((Object.keys(store.state.user.userinfo).length < 1) && to.name != 'Login' && to.name != 'register') {
    next('/login');
  } else {
    next();
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
