<script>
export default {
  name: "MenuItem",
  functional: true,
  props: {
    icon: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
  },
  render(h, context) {
    const { icon, title } = context.props;
    const vnodes = [];

    if (icon) {
      var img = h("img", {
        class: "sub-el-icon",
        style: {
          width: "24px",
          height: "24px",
          verticalAlign: "middle",
        },
        attrs: {
          src: icon,
        },
      });
      vnodes.push(img);
      // vnodes.push(<img src={icon} alt="" />);
    }

    if (title) {
      vnodes.push(
        <span slot="title" style="margin-left: 8px;font-size:14px;">
          {title}
        </span>
      );
    }
    return vnodes;
  },
};
</script>

<style scoped>
.sub-el-icon {
  color: currentColor;
  width: 1em;
  height: 1em;
}
.iconfont {
  color: currentColor;
  width: 1em;
  height: 1em;
}
</style>
